<template>
  <VeeForm class="container" @submit="addSurvey">
    <div class="row">
      <template v-for="(q, index) in qList" :key="'q_' + index">
        <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
          <label :for="'q' + (index + 1)" class="form-label fs-5 mb-4">{{ q }}</label>
          <div class="input-select d-flex justify-content-between">
            沒有問題
            <div class="form-check">
              <Field
                class="form-check-input"
                type="radio"
                :name="'q' + (index + 1)"
                value="1"
                v-model="survey[`q${index + 1}`]"
                :rules="isRequired"
              />
            </div>
            <div class="form-check">
              <Field
                class="form-check-input"
                type="radio"
                :name="'q' + (index + 1)"
                value="2"
                v-model="survey[`q${index + 1}`]"
                :rules="isRequired"
              />
            </div>
            <div class="form-check">
              <Field
                class="form-check-input"
                type="radio"
                :name="'q' + (index + 1)"
                value="3"
                v-model="survey[`q${index + 1}`]"
                :rules="isRequired"
              />
            </div>
            <div class="form-check">
              <Field
                class="form-check-input"
                type="radio"
                :name="'q' + (index + 1)"
                value="4"
                v-model="survey[`q${index + 1}`]"
                :rules="isRequired"
              />
            </div>
            <div class="form-check">
              <Field
                class="form-check-input"
                type="radio"
                :name="'q' + (index + 1)"
                value="5"
                v-model="survey[`q${index + 1}`]"
                :rules="isRequired"
              />
            </div>
            嚴重問題
          </div>
          <ErrorMessage :name="'q' + (index + 1)" v-slot="{ message }">
            <div class="d-flex align-items-center justify-content-center mt-1">
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
              <p class="m-0">{{ message }}</p>
            </div>
          </ErrorMessage>
        </div>
      </template>
    </div>
    <div class="mt-3 text-center col-md-4 offset-md-4">
      <button type="submit" class="btn btn-primary me-3">送出</button>
      <button type="reset" class="btn btn-secondary">重置</button>
    </div>
  </VeeForm>
</template>

<script>
// 載入 firestore
import { collection, addDoc } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 vee-validate
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { db } from '@/assets/firebase';

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    createrId: String,
    projectId: String,
    caseId: String,
  },
  data() {
    return {
      survey: {
        createrId: this.createrId,
        projectId: this.projectId,
        caseId: this.caseId,
        q1: '', // 沒有問題 1 ~ 嚴重問題 5
        q2: '',
        q3: '',
        q4: '',
        q5: '',
        q6: '',
        q7: '',
        q8: '',
        q9: '',
        q10: '',
      },
      qList: [
        '一、吞嚥問題讓我體重減輕。',
        '二、吞嚥問題讓我不能像以前一樣外出用餐。',
        '三、喝飲料時要花很多力氣。',
        '四、吃固體食物時要花很多力氣。',
        '五、吞藥丸時要花很多力氣。',
        '六、吞嚥的過程會引起疼痛。',
        '七、吞嚥問題讓我無法享受用餐。',
        '八、進食後會感到有東西黏在喉嚨上。',
        '九、進食的時候會咳嗽。',
        '十、吞嚥的過程讓我感到有壓力。',
      ],
      isRequired: yup.string().required('此為必填項目'),
    };
  },
  methods: {
    async addSurvey(values, { resetForm }) {
      const now = Date.now();
      // 準備上傳的量表物件
      const surveyData = _.cloneDeep(this.survey);
      surveyData.createdAt = now;
      surveyData.updatedAt = now;
      const loader = this.$loading.show();
      try {
        await addDoc(collection(db, 'eat10'), surveyData);
        loader.hide();
        // 新增成功，重新讀取計畫列表並清空表單
        resetForm();
        this.$swal({
          text: '填寫成功',
          icon: 'success',
        });
      } catch (e) {
        loader.hide();
        resetForm();
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
