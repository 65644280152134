<template>
  <VeeForm class="container" @submit="addSurvey">
    <div class="row">
      <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
        <label class="form-label fs-5 mb-4">{{ qList[0].title }}</label>
        <div class="input-select d-flex justify-content-between">
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q1"
              id="q1_1"
              value="1"
              v-model="survey.ans[0]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q1_1">
              極好
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q1"
              id="q1_2"
              value="2"
              v-model="survey.ans[0]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q1_2">
              很好
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q1"
              id="q1_3"
              value="3"
              v-model="survey.ans[0]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q1_3">
              好
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q1"
              id="q1_4"
              value="4"
              v-model="survey.ans[0]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q1_4">
              普通
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q1"
              id="q1_5"
              value="5"
              v-model="survey.ans[0]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q1_5">
              不好
            </label>
          </div>
        </div>
        <ErrorMessage name="q1" v-slot="{ message }">
          <div class="d-flex align-items-center justify-content-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
    </div>
    <div class="row">
      <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
        <label class="form-label fs-5 mb-4">{{ qList[1].title }}</label>
        <div class="input-select d-flex justify-content-between">
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q2"
              id="q2_1"
              value="1"
              v-model="survey.ans[1]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q2_1">
              好很多
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q2"
              id="q2_2"
              value="2"
              v-model="survey.ans[1]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q2_2">
              好一些
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q2"
              id="q2_3"
              value="3"
              v-model="survey.ans[1]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q2_3">
              差不多
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q2"
              id="q2_4"
              value="4"
              v-model="survey.ans[1]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q2_4">
              差一點
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q2"
              id="q2_5"
              value="5"
              v-model="survey.ans[1]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q2_5">
              差很多
            </label>
          </div>
        </div>
        <ErrorMessage name="q2" v-slot="{ message }">
          <div class="d-flex align-items-center justify-content-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
    </div>
    <div class="row">
      <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
        <label class="form-label fs-5">{{ qList[2].title }}</label>
        <div class="explain row text-center mb-2 sticky-top border-top border-bottom">
          <div class="col-4 border-start">會，受到很多限制</div>
          <div class="col-4 border-start border-end">會，受到一些限制</div>
          <div class="col-4 border-end">不會，完全不受限制</div>
        </div>
        <template v-for="(q, index) in qList[2].subQues" :key="'q3_' + index">
          <div class="row mb-3">
            <label class="form-label fs-5 mb-2">{{ q }}</label>
            <div class="p-0 d-flex">
              <div class="col-4 d-flex justify-content-center border-start">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q3_' + index"
                    value="1"
                    v-model="survey.ans[2].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="col-4 d-flex justify-content-center border-start border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q3_' + index"
                    value="2"
                    v-model="survey.ans[2].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="col-4 d-flex justify-content-center border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q3_' + index"
                    value="3"
                    v-model="survey.ans[2].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
            </div>
            <ErrorMessage :name="'q3_' + index" v-slot="{ message }">
              <div class="d-flex align-items-center justify-content-center mt-1">
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
                <p class="m-0">{{ message }}</p>
              </div>
            </ErrorMessage>
          </div>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
        <label class="form-label fs-5 mb-2">{{ qList[3].title }}</label>
        <div class="explain row text-center mb-2 border-top border-bottom">
          <div class="w-20 border-start">一直都是</div>
          <div class="w-20 border-start ">大部份時間</div>
          <div class="w-20 border-start border-end">有時</div>
          <div class="w-20 border-end">很少</div>
          <div class="w-20 border-end">從不</div>
        </div>
        <template v-for="(q, index) in qList[3].subQues" :key="'q4_' + index">
          <div class="row mb-3">
            <label class="form-label fs-5 mb-2">{{ q }}</label>
            <div class="p-0 d-flex">
              <div class="w-20 d-flex justify-content-center border-start">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q4_' + index"
                    :id="'q4_' + index + '_1'"
                    value="1"
                    v-model="survey.ans[3].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-start">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q4_' + index"
                    :id="'q4_' + index + '_2'"
                    value="2"
                    v-model="survey.ans[3].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-start border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q4_' + index"
                    :id="'q4_' + index + '_3'"
                    value="3"
                    v-model="survey.ans[3].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q4_' + index"
                    :id="'q4_' + index + '_4'"
                    value="4"
                    v-model="survey.ans[3].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q4_' + index"
                    :id="'q4_' + index + '_5'"
                    value="5"
                    v-model="survey.ans[3].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
            </div>
            <ErrorMessage :name="'q4_' + index" v-slot="{ message }">
              <div class="d-flex align-items-center justify-content-center mt-1">
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
                <p class="m-0">{{ message }}</p>
              </div>
            </ErrorMessage>
          </div>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
        <label class="form-label fs-5 mb-4">{{ qList[4].title }}</label>
        <div class="explain row text-center mb-2 border-top border-bottom">
          <div class="w-20 border-start">一直都是</div>
          <div class="w-20 border-start ">大部份時間</div>
          <div class="w-20 border-start border-end">有時</div>
          <div class="w-20 border-end">很少</div>
          <div class="w-20 border-end">從不</div>
        </div>
        <template v-for="(q, index) in qList[4].subQues" :key="'q5_' + index">
          <div class="row mb-3">
            <label class="form-label fs-5 mb-2">{{ q }}</label>
            <div class="p-0 d-flex">
              <div class="w-20 d-flex justify-content-center border-start">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q5_' + index"
                    :id="'q5_' + index + '_1'"
                    value="1"
                    v-model="survey.ans[4].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-start">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q5_' + index"
                    :id="'q5_' + index + '_2'"
                    value="2"
                    v-model="survey.ans[4].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-start border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q5_' + index"
                    :id="'q5_' + index + '_3'"
                    value="3"
                    v-model="survey.ans[4].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q5_' + index"
                    :id="'q5_' + index + '_4'"
                    value="4"
                    v-model="survey.ans[4].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q5_' + index"
                    :id="'q5_' + index + '_5'"
                    value="5"
                    v-model="survey.ans[4].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
            </div>
            <ErrorMessage :name="'q5_' + index" v-slot="{ message }">
              <div class="d-flex align-items-center justify-content-center mt-1">
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
                <p class="m-0">{{ message }}</p>
              </div>
            </ErrorMessage>
          </div>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
        <label class="form-label fs-5 mb-4">{{ qList[5].title }}</label>
        <div class="input-select d-flex justify-content-between">
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q6"
              id="q6_1"
              value="1"
              v-model="survey.ans[5]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q6_1">
              完全沒有妨礙
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q6"
              id="q6_2"
              value="2"
              v-model="survey.ans[5]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q6_2">
              有一點妨礙
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q6"
              id="q6_3"
              value="3"
              v-model="survey.ans[5]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q6_3">
              中度妨礙
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q6"
              id="q6_4"
              value="4"
              v-model="survey.ans[5]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q6_4">
              相當多妨礙
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q6"
              id="q6_5"
              value="5"
              v-model="survey.ans[5]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q6_5">
              妨礙到極點
            </label>
          </div>
        </div>
        <ErrorMessage name="q6" v-slot="{ message }">
          <div class="d-flex align-items-center justify-content-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
    </div>
    <div class="row">
      <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
        <label class="form-label fs-5 mb-4">{{ qList[6].title }}</label>
        <div class="input-select d-flex justify-content-between">
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q7"
              id="q7_1"
              value="1"
              v-model="survey.ans[6]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q7_1">
              完全不
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q7"
              id="q7_2"
              value="2"
              v-model="survey.ans[6]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q7_2">
              非常輕微
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q7"
              id="q7_3"
              value="3"
              v-model="survey.ans[6]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q7_3">
              輕微
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q7"
              id="q7_4"
              value="4"
              v-model="survey.ans[6]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q7_4">
              中度
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q7"
              id="q7_5"
              value="5"
              v-model="survey.ans[6]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q7_5">
              嚴重
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q7"
              id="q7_6"
              value="6"
              v-model="survey.ans[6]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q7_6">
              非常嚴重
            </label>
          </div>
        </div>
        <ErrorMessage name="q7" v-slot="{ message }">
          <div class="d-flex align-items-center justify-content-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
    </div>
    <div class="row">
      <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
        <label class="form-label fs-5 mb-4">{{ qList[7].title }}</label>
        <div class="input-select d-flex justify-content-between">
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q8"
              id="q8_1"
              value="1"
              v-model="survey.ans[7]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q8_1">
              完全沒有妨礙
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q8"
              id="q8_2"
              value="2"
              v-model="survey.ans[7]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q8_2">
              有一點妨礙
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q8"
              id="q8_3"
              value="3"
              v-model="survey.ans[7]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q8_3">
              中度妨礙
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q8"
              id="q8_4"
              value="4"
              v-model="survey.ans[7]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q8_4">
              相當多妨礙
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q8"
              id="q8_5"
              value="5"
              v-model="survey.ans[7]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q8_5">
              妨礙到極點
            </label>
          </div>
        </div>
        <ErrorMessage name="q8" v-slot="{ message }">
          <div class="d-flex align-items-center justify-content-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
    </div>
    <div class="row">
      <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
        <label class="form-label fs-5 mb-4">{{ qList[8].title }}</label>
        <div class="explain row text-center mb-2 sticky-top border-top border-bottom">
          <div class="w-20 border-start">一直都是</div>
          <div class="w-20 border-start ">大部份時間</div>
          <div class="w-20 border-start border-end">有時</div>
          <div class="w-20 border-end">很少</div>
          <div class="w-20 border-end">從不</div>
        </div>
        <template v-for="(q, index) in qList[8].subQues" :key="'q9_' + index">
          <div class="row mb-3">
            <label class="form-label fs-5 mb-2">{{ q }}</label>
            <div class="p-0 d-flex">
              <div class="w-20 d-flex justify-content-center border-start">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q9_' + index"
                    :id="'q9_' + index + '_1'"
                    value="1"
                    v-model="survey.ans[8].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-start">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q9_' + index"
                    :id="'q9_' + index + '_2'"
                    value="2"
                    v-model="survey.ans[8].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-start border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q9_' + index"
                    :id="'q9_' + index + '_3'"
                    value="3"
                    v-model="survey.ans[8].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q9_' + index"
                    :id="'q9_' + index + '_4'"
                    value="4"
                    v-model="survey.ans[8].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q9_' + index"
                    :id="'q9_' + index + '_5'"
                    value="5"
                    v-model="survey.ans[8].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
            </div>
            <ErrorMessage :name="'q9_' + index" v-slot="{ message }">
              <div class="d-flex align-items-center justify-content-center mt-1">
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
                <p class="m-0">{{ message }}</p>
              </div>
            </ErrorMessage>
          </div>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
        <label class="form-label fs-5 mb-4">{{ qList[9].title }}</label>
        <div class="input-select d-flex justify-content-between">
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q10"
              id="q10_1"
              value="1"
              v-model="survey.ans[9]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q10_1">
              一直都會
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q10"
              id="q10_2"
              value="2"
              v-model="survey.ans[9]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q10_2">
              大部分時間會
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q10"
              id="q10_3"
              value="3"
              v-model="survey.ans[9]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q10_3">
              有時候會
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q10"
              id="q10_4"
              value="4"
              v-model="survey.ans[9]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q10_4">
              很少會
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="q10"
              id="q10_5"
              value="5"
              v-model="survey.ans[9]"
              :rules="isRequired"
            />
            <label class="form-check-label" for="q10_5">
              從不會
            </label>
          </div>
        </div>
        <ErrorMessage name="q10" v-slot="{ message }">
          <div class="d-flex align-items-center justify-content-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
    </div>
    <div class="row">
      <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
        <label class="form-label fs-5 mb-4">{{ qList[10].title }}</label>
        <div class="explain row text-center mb-2 border-top border-bottom">
          <div class="w-20 border-start">一直都是</div>
          <div class="w-20 border-start ">大部份時間</div>
          <div class="w-20 border-start border-end">有時</div>
          <div class="w-20 border-end">很少</div>
          <div class="w-20 border-end">從不</div>
        </div>
        <template v-for="(q, index) in qList[10].subQues" :key="'q11_' + index">
          <div class="row mb-3">
            <label class="form-label fs-5 mb-2">{{ q }}</label>
            <div class="p-0 d-flex">
              <div class="w-20 d-flex justify-content-center border-start">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q11_' + index"
                    :id="'q11_' + index + '_1'"
                    value="1"
                    v-model="survey.ans[10].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-start">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q11_' + index"
                    :id="'q11_' + index + '_2'"
                    value="2"
                    v-model="survey.ans[10].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-start border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q11_' + index"
                    :id="'q11_' + index + '_3'"
                    value="3"
                    v-model="survey.ans[10].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q11_' + index"
                    :id="'q11_' + index + '_4'"
                    value="4"
                    v-model="survey.ans[10].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
              <div class="w-20 d-flex justify-content-center border-end">
                <div class="form-check">
                  <Field
                    class="form-check-input"
                    type="radio"
                    :name="'q11_' + index"
                    :id="'q11_' + index + '_5'"
                    value="5"
                    v-model="survey.ans[10].path[index]"
                    :rules="isRequired"
                  />
                </div>
              </div>
            </div>
            <ErrorMessage :name="'q11_' + index" v-slot="{ message }">
              <div class="d-flex align-items-center justify-content-center mt-1">
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
                <p class="m-0">{{ message }}</p>
              </div>
            </ErrorMessage>
          </div>
        </template>
      </div>
    </div>
    <div class="mt-3 text-center col-md-4 offset-md-4">
      <button type="submit" class="btn btn-primary me-3">送出</button>
      <button type="reset" class="btn btn-secondary">重置</button>
    </div>
  </VeeForm>
</template>

<script>
// 載入 firestore
// eslint-disable-next-line object-curly-newline
import { collection, addDoc } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 vee-validate
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { db } from '@/assets/firebase';

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    createrId: String,
    projectId: String,
    caseId: String,
  },
  data() {
    return {
      survey: {
        createrId: this.createrId,
        projectId: this.projectId,
        caseId: this.caseId,
        ans: [
          '',
          '',
          { path: ['', '', '', '', '', '', '', '', '', ''] },
          { path: ['', '', '', ''] },
          { path: ['', '', ''] },
          '',
          '',
          '',
          { path: ['', '', '', '', '', '', '', '', ''] },
          '',
          { path: ['', '', '', ''] },
        ],
      },
      qList: [
        {
          title: '1. 一般來說，您認為您目前的健康狀況是？',
        },
        {
          title: '2. 和一年前比較，您認為您目前的健康狀況是？',
        },
        {
          title:
            '3. 下面是一些您日常可能從事的活動，請問您目前健康狀況會不會限制您從事這些活動？如果會，到底限制有多少？',
          subQues: [
            'a. 費力活動，例如跑步、提重物、參與劇烈運動',
            'b. 中等程度活動，例如搬桌子、拖地板、打保齡球、或打太極拳',
            'c. 提起或攜帶食品雜貨',
            'd. 爬數層樓樓梯',
            'e. 爬一層樓樓梯',
            'f. 彎腰、跪下、或蹲下',
            'g. 走路超過1公里',
            'h. 走數百公尺',
            'i. 走一百公尺',
            'j. 自己洗澡或穿衣',
          ],
        },
        {
          title:
            '4. 在過去四個星期內，您有多少時候因為身體健康問題，而在工作上或其他日常活動方面有下列任何的問題？',
          subQues: [
            'a. 做工作或其他活動的時間減少',
            'b. 完成的工作量比您想要完成的較少',
            'c. 可以做的工作或其他活動的種類受到限制',
            'd. 做工作或其他活動有困難(例如，須更吃力)',
          ],
        },
        {
          title:
            '5. 在過去四個星期內，您有多少時候因為情緒問題(例如，感覺沮喪或焦慮)，而在工作上或其他日常活動方面有下列的問題？',
          subQues: [
            'a. 做工作或其他活動的時間減少',
            'b. 完成的工作量比您想要完成的較少',
            'c. 做工作或其他活動時，沒有平常來的小心',
          ],
        },
        {
          title:
            '6. 在過去四個星期內，您的健康或情緒問題，對您與家人或朋友、鄰居、社團間的平常活動的妨礙程度如何？',
        },
        {
          title: '7. 在過去四個星期內，您身體疼痛程度有多嚴重？',
        },
        {
          title: '8. 在過去四個星期內，身體疼痛對您的日常工作(包括上半及家務)妨礙程度如何？',
        },
        {
          title:
            '9. 下列各項問題是關於過去四個星期內您的感覺及您對周遭生活的感受，請針對每一問題選一最接近您感覺的答案。在過去四個星期中有多少時候…',
          subQues: [
            'a. 您覺得充滿活力？',
            'b. 您是一個非常緊張的人？',
            'c. 您覺得非常沮喪，沒有任何事情可以讓您高興起來？',
            'd. 您覺得心情平靜？',
            'e. 您精神充沛？',
            'f. 您覺得悶悶不樂和憂鬱？',
            'g. 您覺得筋疲力竭？',
            'h. 您是一個快樂的人？',
            'i. 您覺得累？',
          ],
        },
        {
          title:
            '10. 在過去四星期內，您的身體健康或情緒問題有多少時候會妨礙您的社交活動（如拜訪親友等）？',
        },
        {
          title: '11. 下列各個陳述對您來說有多正確',
          subQues: [
            'a. 我好像比別人較容易生病。',
            'b. 和任何一個我認識的人來比，我和他們一樣健康。',
            'c. 我想我的健康會越來越壞。',
            'd. 我的健康狀況好得很。',
          ],
        },
      ],
      isRequired: yup.string().required('此為必填項目'),
    };
  },
  methods: {
    async addSurvey(values, { resetForm }) {
      const now = Date.now();
      // 準備上傳的量表物件
      const surveyData = _.cloneDeep(this.survey);
      surveyData.createdAt = now;
      surveyData.updatedAt = now;
      const loader = this.$loading.show();
      try {
        await addDoc(collection(db, 'sf36'), surveyData);
        loader.hide();
        // 新增成功，重新讀取計畫列表並清空表單
        resetForm();
        this.$swal({
          text: '填寫成功',
          icon: 'success',
        });
      } catch (e) {
        loader.hide();
        resetForm();
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.explain {
  background-color: $bright;
}
.w-20 {
  width: 20%;
}
</style>
