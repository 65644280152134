<template>
  <VeeForm class="container" @submit="addSurvey">
    <VueLoading v-model:active="isLoading" color="#196bb0"></VueLoading>
    <div class="row">
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
        <label class="form-label fs-5 mb-3">{{ qList[0] }}</label>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q1"
            id="q1_1"
            value="1"
            v-model.number="survey.q1"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q1_1">1分：是，有改變</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q1"
            id="q1_0"
            value="0"
            v-model.number="survey.q1"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q1_0">0分：不是，沒有改變</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q1"
            id="q1_8888"
            value="8888"
            v-model.number="survey.q1"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q1_8888">0分：不知道</label>
        </div>
        <ErrorMessage name="q1" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="total-score col-md-4 fs-5">
        總分：{{ survey.total }} <br />
        <span class="fs-6">若總得分大於或等於 2 分，這時候您需要至身心內科門診，透過更詳細的檢查，由專業醫師進行診斷及治療。</span>
      </div>
      <template v-for="(q, index) in qList.slice(1)" :key="'q_' + index">
        <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
          <label class="form-label fs-5 mb-3">{{ q }}</label>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              :name="'q' + (index + 2)"
              :id="'q' + (index + 2) + '_1'"
              value="1"
              v-model.number="survey[`q${index + 2}`]"
              :rules="isRequired"
            />
            <label class="form-check-label" :for="'q' + (index + 2) + '_1'">1分：是，有改變</label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              :name="'q' + (index + 2)"
              :id="'q' + (index + 2) + '_0'"
              value="0"
              v-model.number="survey[`q${index + 2}`]"
              :rules="isRequired"
            />
            <label class="form-check-label" :for="'q' + (index + 2) + '_0'"
              >0分：不是，沒有改變</label
            >
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              :name="'q' + (index + 2)"
              :id="'q' + (index + 2) + '_8888'"
              value="8888"
              v-model.number="survey[`q${index + 2}`]"
              :rules="isRequired"
            />
            <label class="form-check-label" :for="'q' + (index + 2) + '_8888'">0分：不知道</label>
          </div>
          <ErrorMessage :name="'q' + (index + 2)" v-slot="{ message }">
            <div class="d-flex align-items-center mt-1">
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
              <p class="m-0">{{ message }}</p>
            </div>
          </ErrorMessage>
        </div>
      </template>
    </div>
    <div class="mt-3 text-center col-md-4 offset-md-4">
      <button type="submit" class="btn btn-primary me-3">送出</button>
      <button type="reset" @click="customReset" class="btn btn-secondary">重置</button>
    </div>
  </VeeForm>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
// 載入 loading overlay
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// 載入 firestore
import { collection, addDoc } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 vee-validate
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { db } from '@/assets/firebase';
// 載入 sweetalert2
import useVueSweetAlert2 from '@/composables/useVueSweetAlert2';

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
    VueLoading,
  },
  props: {
    createrId: String,
    projectId: String,
    caseId: String,
  },
  setup(props) {
    const $swal = useVueSweetAlert2();
    // ================================
    const { createrId, projectId, caseId } = toRefs(props);
    const survey = ref({
      createrId,
      projectId,
      caseId,
      q1: null,
      q2: null,
      q3: null,
      q4: null,
      q5: null,
      q6: null,
      q7: null,
      q8: null,
      total: 0, // 總分
    });
    const qList = ref([
      '判斷力上的困難：例如落入圈套或騙局、財務上不好的決定、買了對受禮者不合宜的禮物',
      '對活動和嗜好的興趣降低',
      '重複相同的問題、故事和陳述',
      '在學習如何使用工具、設備、和小器具上有困難。例如：電視、音響、冷氣機、洗衣機、熱水爐（器）、微波爐、遙控器',
      '忘記正確的月份和年份',
      '處理複雜的財務上有困難。例如：個人或家庭的收支平衡、所得稅、繳費單',
      '記住約會的時間有困難',
      '有持續的思考和技藝方面的問題',
    ]);
    const isRequired = ref(
      yup
        .string()
        .typeError('此為必填項目')
        .required('此為必填項目'),
    );
    const isLoading = ref(false);

    function customReset() {
      // 清空量表物件
      survey.value = {
        createrId,
        projectId,
        caseId,
        q1: null,
        q2: null,
        q3: null,
        q4: null,
        q5: null,
        q6: null,
        q7: null,
        q8: null,
        total: 0, // 總分
      };
    }
    async function addSurvey(values, { resetForm }) {
      const now = Date.now();
      // 準備上傳的量表物件
      const surveyData = _.cloneDeep(survey.value);
      surveyData.createdAt = now;
      surveyData.updatedAt = now;
      // 開始上傳
      isLoading.value = true;
      try {
        await addDoc(collection(db, 'ad8'), surveyData);
        isLoading.value = false;
        customReset();
        resetForm();
        $swal.fire({
          text: '填寫成功',
          icon: 'success',
        });
      } catch (e) {
        isLoading.value = false;
        customReset();
        resetForm();
        $swal.fire({
          text: String(e),
          icon: 'error',
        });
      }
    }
    function calFinalScore() {
      const scoreList = [];
      let totalScore = 0;
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < 8; index++) {
        let score = survey.value[`q${index + 1}`];
        if (score === 8888) {
          score = 0;
        }
        scoreList.push(score);
      }
      scoreList.forEach((score) => {
        totalScore += Number(score);
      });
      survey.value.total = totalScore;
    }
    // 監聽 survey 內各項參數變動，並進行即時計算
    watch(
      survey,
      () => {
        // 計算最新總分
        calFinalScore();
      },
      { deep: true },
    );

    return {
      survey,
      qList,
      isRequired,
      isLoading,
      addSurvey,
      customReset,
    };
  },
};
</script>

<style lang="scss" scoped>
div {
  border-color: $primary !important;
}
.total-score {
  position: sticky;
  top: 1rem;
}
</style>
