<template>
  <VeeForm class="container" @submit="addSurvey">
    <VueLoading v-model:active="isLoading" color="#196bb0"></VueLoading>
    <div class="row">
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
        <label class="form-label fs-5 mb-3">{{ qList[0] }}</label>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q1"
            id="q1_2"
            value="2"
            v-model.number="survey.q1"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q1_2">2分：食量沒有改變</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q1"
            id="q1_1"
            value="1"
            v-model.number="survey.q1"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q1_1">1分：食量中度減少</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q1"
            id="q1_0"
            value="0"
            v-model.number="survey.q1"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q1_0">0分：食量嚴重減少</label>
        </div>
        <ErrorMessage name="q1" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="total-score col-md-4 fs-5">
        總分：{{ survey.total }} <br />
        <span v-show="survey.total > 11">正常營養狀況</span>
        <span v-show="survey.total > 7 && survey.total <= 11">有營養不良的風險</span>
        <span v-show="survey.total <= 7">營養不良</span>
      </div>
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
        <div class="d-flex align-items-center mb-3 justify-content-between">
          <label class="form-label fs-5 m-0">{{ qList[1] }}</label>
          <div class="form-check form-switch m-0">
            <label class="form-check-label" for="no-lose-weight-switch">不知道</label>
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="no-lose-weight-switch"
              v-model="ifNoLoseWeight"
            />
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6 mt-2" v-if="!ifNoLoseWeight">
            <div>
              下降
              <Field
                type="number"
                class="form-control d-inline-block w-50 text-center"
                id="loseWeight"
                name="loseWeight"
                v-model.number="survey.loseWeight"
                :rules="notMinusWeight"
              />
              公斤
            </div>
          </div>
          <div class="mb-3 col-md-6" v-show="survey.q2 !== null">
            <label class="form-label fs-5">分數</label>
            <div class="display-auto-cal">
              {{ survey.q2 }}分：
              <span v-show="survey.q2 === 3">
                體重沒有下降
              </span>
              <span v-show="survey.q2 === 2">
                體重下降 1-3 公斤
              </span>
              <span v-show="survey.q2 === 1">
                不知道
              </span>
              <span v-show="survey.q2 === 0">
                體重下降大於 3 公斤
              </span>
            </div>
          </div>
        </div>
        <ErrorMessage name="q2" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
        <ErrorMessage name="loseWeight" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
        <label class="form-label fs-5 mb-3">{{ qList[2] }}</label>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q3"
            id="q3_2"
            value="2"
            v-model.number="survey.q3"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q3_2">2分：可以外出</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q3"
            id="q3_1"
            value="1"
            v-model.number="survey.q3"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q3_1">1分：可以下床或離開輪椅，但不能外出</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q3"
            id="q3_0"
            value="0"
            v-model.number="survey.q3"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q3_0">0分：需長期臥床或坐輪椅</label>
        </div>
        <ErrorMessage name="q3" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
        <label class="form-label fs-5 mb-3">{{ qList[3] }}</label>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q4"
            id="q4_2"
            value="2"
            v-model.number="survey.q4"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q4_2">2分：沒有</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q4"
            id="q4_0"
            value="0"
            v-model.number="survey.q4"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q4_0">0分：有</label>
        </div>
        <ErrorMessage name="q4" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
        <label class="form-label fs-5 mb-3">{{ qList[4] }}</label>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q5"
            id="q5_2"
            value="2"
            v-model.number="survey.q5"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q5_2">2分：沒有精神心理問題</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q5"
            id="q5_1"
            value="1"
            v-model.number="survey.q5"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q5_1">1分：輕度失智</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q5"
            id="q5_0"
            value="0"
            v-model.number="survey.q5"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q5_0">0分：嚴重失智或抑鬱</label>
        </div>
        <ErrorMessage name="q5" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom" v-if="!ifQ62">
        <div class="d-flex align-items-center mb-3 justify-content-between">
          <label class="form-label fs-5 m-0">{{ qList[5] }}</label>
          <div class="form-check form-switch m-0">
            <label class="form-check-label" for="q62-switch">無法取得BMI</label>
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="q62-switch"
              v-model="ifQ62"
            />
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label for="height" class="form-label fs-5">身高</label>
            <div>
              <Field
                type="number"
                class="form-control d-inline-block w-75"
                id="height"
                name="height"
                v-model.number="survey.height"
                :rules="notZeroHeight"
              />
              公分
            </div>
          </div>
          <div class="mb-3 col-md-6">
            <label for="weight" class="form-label fs-5">體重</label>
            <div>
              <Field
                type="number"
                class="form-control d-inline-block w-75"
                id="weight"
                name="weight"
                v-model.number="survey.weight"
                :rules="notZeroWeight"
              />
              公斤
            </div>
          </div>
        </div>
        <div class="row" v-show="survey.bmi">
          <div class="mb-3 col-md-6">
            <label class="form-label fs-5">BMI</label>
            <div class="display-auto-cal">
              {{ survey.bmi }}
            </div>
          </div>
          <div class="mb-3 col-md-6">
            <label class="form-label fs-5">分數</label>
            <div class="display-auto-cal">
              {{ survey.q6_1 }}分：
              <span v-show="survey.q6_1 === 3">
                BMI 相等或大於 23
              </span>
              <span v-show="survey.q6_1 === 2">
                BMI 21 至低於 23
              </span>
              <span v-show="survey.q6_1 === 1">
                BMI 19 至低於 21
              </span>
              <span v-show="survey.q6_1 === 0">
                BMI 低於 19
              </span>
            </div>
          </div>
        </div>
        <ErrorMessage name="height" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
        <ErrorMessage name="weight" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom" v-if="ifQ62">
        <div class="d-flex align-items-center mb-3 justify-content-between">
          <label class="form-label fs-5 m-0">{{ qList[6] }}</label>
          <div class="form-check form-switch m-0">
            <label class="form-check-label" for="cc-switch">無法取得BMI</label>
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="cc-switch"
              v-model="ifQ62"
            />
          </div>
        </div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <div>
              <label for="weight" class="form-label fs-5">小腿圍</label>
              <Field
                type="number"
                class="form-control d-inline-block w-75"
                id="cc"
                name="cc"
                v-model.number="survey.cc"
                :rules="notZeroCC"
              />
              公分
            </div>
          </div>
          <div class="mb-3 col-md-6" v-show="survey.q6_2 !== null && survey.q6_2 >= 0">
            <label class="form-label fs-5">分數</label>
            <div class="display-auto-cal">
              {{ survey.q6_2 }}分：
              <span v-show="survey.q6_2 === 3">
                &ge; 31 公分
              </span>
              <span v-show="survey.q6_2 === 0">
                &lt; 31 公分
              </span>
            </div>
          </div>
        </div>
        <ErrorMessage name="cc" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
    </div>
    <div class="mt-3 text-center col-md-4 offset-md-4">
      <button type="submit" class="btn btn-primary me-3">送出</button>
      <button type="reset" @click="customReset" class="btn btn-secondary">重置</button>
    </div>
  </VeeForm>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
// 載入 loading overlay
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// 載入 firestore
import { collection, addDoc } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 vee-validate
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { db } from '@/assets/firebase';
// 載入 sweetalert2
import useVueSweetAlert2 from '@/composables/useVueSweetAlert2';

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
    VueLoading,
  },
  props: {
    createrId: String,
    projectId: String,
    caseId: String,
  },
  setup(props) {
    const $swal = useVueSweetAlert2();
    // ================================
    const { createrId, projectId, caseId } = toRefs(props);
    const survey = ref({
      createrId,
      projectId,
      caseId,
      q1: null,
      q2: null,
      q3: null,
      q4: null,
      q5: null,
      q6_1: null,
      q6_2: null,
      total: 0, // 總分
      height: 0,
      weight: 0,
      bmi: 0,
      cc: 0,
      loseWeight: null, // 8888 代表不知道
    });
    const qList = ref([
      '一、過去三個月內有沒有因為食慾不振、消化問題、咀嚼或吞嚥困難而減少食量？',
      '二、過去三個月體重下降的情形',
      '三、活動能力',
      '四、過去三個月內有沒有受到心理創傷或患上急性疾病？',
      '五、精神心理問題',
      '六、身體質量指數(BMI)',
      '六、小腿圍(CC)',
    ]);
    const isRequired = ref(
      yup
        .string()
        .typeError('此為必填項目')
        .required('此為必填項目'),
    );
    const notZeroHeight = ref(
      yup
        .number()
        .typeError('身高為必填項目')
        .moreThan(0, '身高輸入值不得等於或小於 0'),
    );
    const notZeroWeight = ref(
      yup
        .number()
        .typeError('體重為必填項目')
        .moreThan(0, '體重輸入值不得等於或小於 0'),
    );
    const notMinusWeight = ref(
      yup
        .number()
        .typeError('下降體重為必填項目')
        .min(0, '體重輸入值不得為負'),
    );
    const notZeroCC = ref(
      yup
        .number()
        .typeError('小腿圍為必填項目')
        .moreThan(0, '小腿圍輸入值不得等於或小於 0'),
    );
    const isLoading = ref(false);
    const ifNoLoseWeight = ref(false);
    const ifQ62 = ref(false);

    function customReset() {
      // 清空量表物件
      survey.value = {
        createrId,
        projectId,
        caseId,
        q1: null,
        q2: null,
        q3: null,
        q4: null,
        q5: null,
        q6_1: null,
        q6_2: null,
        total: 0,
        height: 0,
        weight: 0,
        bmi: 0,
        cc: 0,
        loseWeight: null, // 8888 代表不知道
      };
      // 重置題目開關
      ifNoLoseWeight.value = false;
      ifQ62.value = false;
    }
    async function addSurvey(values, { resetForm }) {
      const now = Date.now();
      // 準備上傳的量表物件
      const surveyData = _.cloneDeep(survey.value);
      surveyData.createdAt = now;
      surveyData.updatedAt = now;
      // 開始上傳
      isLoading.value = true;
      try {
        await addDoc(collection(db, 'mnasf'), surveyData);
        isLoading.value = false;
        customReset();
        resetForm();
        $swal.fire({
          text: '填寫成功',
          icon: 'success',
        });
      } catch (e) {
        isLoading.value = false;
        customReset();
        resetForm();
        $swal.fire({
          text: String(e),
          icon: 'error',
        });
      }
    }
    function calQ2Score() {
      if (survey.value.loseWeight === 0) {
        survey.value.q2 = 3;
      } else if (survey.value.loseWeight === 8888) {
        survey.value.q2 = 1;
      } else if (survey.value.loseWeight <= 3 && survey.value.loseWeight > 0) {
        survey.value.q2 = 2;
      } else if (survey.value.loseWeight > 3) {
        survey.value.q2 = 0;
      } else {
        survey.value.q2 = null;
      }
    }
    function calQ61Score() {
      if (survey.value.bmi >= 23) {
        survey.value.q6_1 = 3;
      } else if (survey.value.bmi < 23 && survey.value.bmi >= 21) {
        survey.value.q6_1 = 2;
      } else if (survey.value.bmi < 21 && survey.value.bmi >= 19) {
        survey.value.q6_1 = 1;
      } else if (survey.value.bmi < 19) {
        survey.value.q6_1 = 0;
      }
    }
    function calQ62Score() {
      if (survey.value.cc >= 31) {
        survey.value.q6_2 = 3;
      } else if (survey.value.cc < 31 && survey.value.cc > 0) {
        survey.value.q6_2 = 0;
      } else if (survey.value.cc <= 0) {
        survey.value.q6_2 = null;
      }
    }
    function calFinalScore() {
      survey.value.total = survey.value.q1
        + survey.value.q2
        + survey.value.q3
        + survey.value.q4
        + survey.value.q5
        + survey.value.q6_1
        + survey.value.q6_2;
    }
    function calBMI() {
      if (
        survey.value.height
        && survey.value.weight
        && survey.value.height > 0
        && survey.value.weight > 0
      ) {
        let temp = survey.value.weight / (survey.value.height / 100) ** 2;
        temp = (Math.round(temp * 10) / 10).toFixed(1);
        survey.value.bmi = Number(temp);
        calQ61Score();
      } else {
        // bmi 和 q6_1 重置
        survey.value.bmi = 0;
        survey.value.q6_1 = null;
      }
    }
    // 監聽 survey 內各項參數變動，並進行即時計算
    watch(
      survey,
      () => {
        // 監聽身高和體重的輸入，並計算BMI
        calBMI();
        calQ2Score();
        calQ62Score();
        // 計算最新總分
        calFinalScore();
      },
      { deep: true },
    );
    // =================================================================================
    // ================================ 題目開關切換 =====================================
    // =================================================================================
    // 每次切換題目二「不知道」時，重置 loseWeight 或設定為 8888
    watch(ifNoLoseWeight, () => {
      if (ifNoLoseWeight.value === true) {
        survey.value.loseWeight = 8888;
      } else {
        survey.value.loseWeight = null;
      }
    });
    // 每次切換題目六的時候，都重置 q6_1 和 q6_2
    watch(ifQ62, () => {
      survey.value.height = 0;
      survey.value.weight = 0;
      survey.value.bmi = 0;
      survey.value.q6_1 = null;
      survey.value.cc = 0;
      survey.value.q6_2 = null;
    });

    return {
      survey,
      qList,
      isRequired,
      notZeroHeight,
      notZeroWeight,
      notMinusWeight,
      notZeroCC,
      isLoading,
      ifNoLoseWeight,
      ifQ62,
      addSurvey,
      customReset,
    };
  },
};
</script>

<style lang="scss" scoped>
div {
  border-color: $primary !important;
}
.total-score {
  position: sticky;
  top: 1rem;
}
</style>
