<template>
  <VeeForm class="container" @submit="addSurvey">
    <div class="row">
      <div class="mb-3 col-md-4">
        <label for="height" class="form-label fs-5">身高</label>
        <div>
          <Field
            type="number"
            class="form-control d-inline-block w-75"
            id="height"
            name="height"
            v-model.number="survey.height"
            @change="calBMI"
          />
          公分
        </div>
        <ErrorMessage name="height" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="mb-3 col-md-4">
        <label for="weight" class="form-label fs-5">體重</label>
        <div>
          <Field
            type="number"
            class="form-control d-inline-block w-75"
            id="weight"
            name="weight"
            v-model.number="survey.weight"
            @change="calBMI"
          />
          公斤
        </div>
        <ErrorMessage name="weight" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="mb-3 col-md-4">
        <label class="form-label fs-5">BMI</label>
        <div class="display-auto-cal">
          {{ survey.bmi }}
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="waistline" class="form-label fs-5">腰圍</label>
        <div>
          <Field
            type="number"
            class="form-control d-inline-block w-75"
            id="waistline"
            name="waistline"
            v-model.number="survey.waistline"
          />
          公分
        </div>
        <ErrorMessage name="waistline" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="mb-3 col-md-4">
        <label for="activity" class="form-label fs-5">活動量</label>
        <div class="input-select d-flex w-75 justify-content-between">
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="activity"
              id="activity-1"
              value="1"
              v-model="survey.activity"
              @change="calCalories"
            />
            <label class="form-check-label" for="activity-1">
              臥床
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="activity"
              id="activity-2"
              value="2"
              v-model="survey.activity"
              :rules="isRequired"
              @change="calCalories"
            />
            <label class="form-check-label" for="activity-2">
              輕度
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="activity"
              id="activity-3"
              value="3"
              v-model="survey.activity"
              :rules="isRequired"
              @change="calCalories"
            />
            <label class="form-check-label" for="activity-3">
              中度
            </label>
          </div>
          <div class="form-check">
            <Field
              class="form-check-input"
              type="radio"
              name="activity"
              id="activity-4"
              value="4"
              v-model="survey.activity"
              :rules="isRequired"
              @change="calCalories"
            />
            <label class="form-check-label" for="activity-4">
              高度
            </label>
          </div>
        </div>
        <ErrorMessage name="activity" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="mb-3 col-md-4">
        <label for="calories" class="form-label fs-5">每日熱量需求</label>
        <div class="display-auto-cal">{{ survey.calories }} 大卡</div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="fat" class="form-label fs-5">體脂肪</label>
        <div>
          <Field
            type="number"
            class="form-control d-inline-block w-75"
            id="fat"
            name="fat"
            v-model.number="survey.fat"
          />
          ％
        </div>
        <ErrorMessage name="fat" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="mb-3 col-md-4">
        <label for="muscle" class="form-label fs-5">肌肉量</label>
        <div>
          <Field
            type="number"
            class="form-control d-inline-block w-75"
            id="muscle"
            name="muscle"
            v-model.number="survey.muscle"
          />
          公斤
        </div>
        <ErrorMessage name="muscle" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="mb-3 col-md-4">
        <label for="tsf" class="form-label fs-5">三頭肌皮下脂肪厚度</label>
        <div>
          <Field
            type="number"
            class="form-control d-inline-block w-75"
            id="tsf"
            name="tsf"
            v-model.number="survey.tsf"
          />
          公釐
        </div>
        <ErrorMessage name="tsf" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="mb-3 col-md-4">
        <label for="sbp" class="form-label fs-5">收縮壓</label>
        <div>
          <Field
            type="number"
            class="form-control d-inline-block w-75"
            id="sbp"
            name="sbp"
            v-model.number="survey.sbp"
          />
          mmHg
        </div>
        <ErrorMessage name="sbp" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="mb-3 col-md-4">
        <label for="dbp" class="form-label fs-5">舒張壓</label>
        <div>
          <Field
            type="number"
            class="form-control d-inline-block w-75"
            id="dbp"
            name="dbp"
            v-model.number="survey.dbp"
          />
          mmHg
        </div>
        <ErrorMessage name="dbp" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="mb-3 col-md-4">
        <label for="note" class="form-label fs-5">備註</label>
        <textarea class="form-control" name="note" id="note" v-model="survey.note" type="text" />
      </div>
    </div>
    <div class="mt-3 text-center col-md-4 offset-md-4">
      <button type="submit" class="btn btn-primary me-3">送出</button>
      <button type="reset" class="btn btn-secondary">重置</button>
    </div>
  </VeeForm>
</template>

<script>
// 載入 firestore
import { collection, addDoc } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 vee-validate
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { db } from '@/assets/firebase';

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    createrId: String,
    projectId: String,
    caseId: String,
  },
  data() {
    return {
      survey: {
        createrId: this.createrId,
        projectId: this.projectId,
        caseId: this.caseId,
        height: 0, // 身高
        weight: 0, // 體重
        waistline: 0, // 腰圍
        bmi: 0, // BMI
        activity: '', // 活動量：臥床 1 輕度 2 中度 3 高度 4
        calories: '0', // 每日熱量需求
        fat: 0, // 體脂肪
        muscle: 0, // 肌肉量
        tsf: 0, // 三頭肌皮下脂肪厚度
        sbp: 0, // 收縮壓
        dbp: 0, // 舒張壓
        note: '',
      },
      notZero: yup
        .number()
        .typeError('此為必填項目')
        .moreThan(0, '輸入值不得等於或小於 0'),
      isRequired: yup.string().required('此為必填項目'),
    };
  },
  methods: {
    async addSurvey(values, { resetForm }) {
      const now = Date.now();
      // 準備上傳的量表物件
      const surveyData = _.cloneDeep(this.survey);
      surveyData.createdAt = now;
      surveyData.updatedAt = now;
      const loader = this.$loading.show();
      try {
        await addDoc(collection(db, 'basicinfo'), surveyData);
        loader.hide();
        // 新增成功，重新讀取計畫列表並清空表單
        resetForm();
        this.survey.note = '';
        this.$swal({
          text: '填寫成功',
          icon: 'success',
        });
      } catch (e) {
        loader.hide();
        resetForm();
        this.survey.note = '';
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    calBMI() {
      if (this.survey.height && this.survey.weight) {
        let bmi = this.survey.weight / (this.survey.height / 100) ** 2;
        bmi = (Math.round(bmi * 10) / 10).toFixed(1);
        this.survey.bmi = Number(bmi);
        // 重新計算每日熱量需求
        this.calCalories();
      }
    },
    calCalories() {
      if (this.survey.activity && this.survey.bmi) {
        const { activity, bmi } = this.survey;
        if (bmi >= 24) {
          switch (activity) {
            case '1':
              this.survey.calories = '20';
              break;
            case '2':
              this.survey.calories = '20-25';
              break;
            case '3':
              this.survey.calories = '30';
              break;
            case '4':
              this.survey.calories = '35';
              break;
            default:
              break;
          }
        } else if (bmi < 18.5) {
          switch (activity) {
            case '1':
              this.survey.calories = '30';
              break;
            case '2':
              this.survey.calories = '35';
              break;
            case '3':
              this.survey.calories = '40';
              break;
            case '4':
              this.survey.calories = '45';
              break;
            default:
              break;
          }
        } else {
          switch (activity) {
            case '1':
              this.survey.calories = '20-25';
              break;
            case '2':
              this.survey.calories = '30';
              break;
            case '3':
              this.survey.calories = '35';
              break;
            case '4':
              this.survey.calories = '40';
              break;
            default:
              break;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  height: 38px;
  align-items: center;
}
.display-auto-cal {
  height: 38px;
  line-height: 38px;
}
</style>
