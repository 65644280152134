<template>
  <VeeForm class="survey-wrapper container position-sticky top-0 pb-3" @submit="addSurvey">
    <div class="row">
      <div class="mb-3 col-md-2">
        <label for="education" class="form-label fs-5">教育程度</label>
        <div>
          <Field
            as="select"
            class="form-select d-inline-block"
            id="education"
            name="education"
            v-model.number="survey.education"
            :rules="isRequired"
            @change="calCutoff"
          >
            <option selected disabled hidden value="">--請選擇教育程度--</option>
            <option value="1">未受教育</option>
            <option value="2">國小</option>
            <option value="3">國中(含)以上</option>
          </Field>
        </div>
        <ErrorMessage name="education" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="mb-3 col-md-3">
        <label for="career" class="form-label fs-5">職業</label>
        <div>
          <Field
            type="text"
            class="form-control d-inline-block"
            id="career"
            name="career"
            v-model.number="survey.career"
            :rules="isRequired"
          />
        </div>
        <ErrorMessage name="career" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div
        class="mb-3 d-flex flex-column"
        :class="{ 'col-md-3': !isOverflow, 'col-md-5': isOverflow }"
      >
        <label class="form-label fs-5">界斷分數</label>
        <div class="d-flex flex-grow-1 align-items-center">
          {{ cutoff ? cutoff : '---' }}
        </div>
      </div>
      <div class="mb-3 col-md-2">
        <label for="total" class="form-label fs-5">總分</label>
        <div>
          <Field
            type="number"
            class="form-control d-inline-block w-75"
            id="total"
            name="total"
            v-model.number="survey.total"
            :rules="isRequiredNum"
          />
          分
        </div>
        <ErrorMessage name="total" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
    </div>
    <div class="mt-3 text-center col-md-4 offset-md-4">
      <button type="submit" class="btn btn-primary me-3">送出</button>
      <button type="reset" class="btn btn-secondary">重置</button>
    </div>
  </VeeForm>
  <div class="quest-wrapper container py-3">
    <div class="row">
      <h3>・Orientation(10) ____：□年□月□日□星期□時；□醫院□科 □省□縣市□區</h3>
      <p class="fs-5">1. 現在：今天是民國___年___月___日，星期___；現在何時？上/中/下午</p>
      <p class="fs-5">
        2. 地方：我們現在在什麼地方？這裡是___醫院___科，這醫院是在___省___縣/市___區/鄉/鎮
      </p>
    </div>
    <div class="row mt-3">
      <h3>・Registration(3) ____：□眼鏡 □紅色 □老實 / □樹木 □剪刀 □火車</h3>
      <p class="fs-5">
        3. 我現在要講三種東西，請你注意聽，等我念完，你再重複念一遍。（嘗試___次， 最多 3 次）
      </p>
    </div>
    <div class="row mt-3">
      <h3>・Attention(5) ____：□□□□□ ____、____、____、____、____</h3>
      <p class="fs-5">
        4. 請告訴我 100-7（或 20-3）還有多少？再減 7？（共連續 5 次）（第一次答錯可提示正確數字，
        但不算分，再鼓勵減 7）
      </p>
    </div>
    <div class="row mt-3">
      <h3>・STM(3) ____：□眼鏡 □紅色 □老實 / □樹木 □剪刀 □火車</h3>
      <p class="fs-5">5. 請告訴我剛才請你記下來的三種東西是什麼？</p>
    </div>
    <div class="row mt-3">
      <h3>・Naming(2) ____：□鉛筆 □手錶（生活物品皆可）</h3>
      <p class="fs-5">6. 請問這是什麼？</p>
    </div>
    <div class="row mt-3">
      <h3>・Repetition(1) ____：□『（國）沒來有往不自在』or □『（台）沒來有去真趣味』</h3>
      <p class="fs-5">7. 我說一句話，等我說完，請你跟著說一遍。</p>
    </div>
    <div class="row mt-3">
      <h3>・Reading(1) ____：□請閉上眼睛</h3>
      <p class="fs-5">8. 請念一遍，並做出下圖要求的動作</p>
      <p class="q8">請閉上眼睛</p>
    </div>
    <div class="row mt-3">
      <h3>・Writing(1) ____：□句子 / □名字</h3>
      <p class="fs-5">
        9.
        請在這裡寫出一句話或一個句子，語意完整的句子（主詞＋動詞/＋受詞）。（請寫你的名字/不計分）
      </p>
    </div>
    <div class="row mt-3 pb-3">
      <h3>・Construction(1) ____：□兩個重疊的五邊形（交叉為四邊形）</h3>
      <p class="fs-5">10. 請看這張圖，請你畫一個一樣的在旁邊。</p>
      <img class="w-50" src="/img/mmse_q10.png" alt="q10" />
    </div>
    <div class="row mt-3">
      <h3>・Execution(3) ____：□用你的左（右）手來拿這張紙 □將他對折一半 □然後交還給我</h3>
      <p class="fs-5">11. 我現在要請你做三個動作，等我講完，你再開始依順序一個接一個做。</p>
    </div>
  </div>
</template>

<script>
// 載入 firestore
import { collection, addDoc } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 vee-validate
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { db } from '@/assets/firebase';

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    createrId: String,
    projectId: String,
    caseId: String,
  },
  data() {
    return {
      survey: {
        createrId: this.createrId,
        projectId: this.projectId,
        caseId: this.caseId,
        education: '', // 1 代表未受教育、2 代表國小、3 代表國中以上
        career: '',
        total: null,
      },
      cutoff: '',
      isOverflow: false,
      isRequired: yup.string().required('此為必填項目'),
      isRequiredNum: yup
        .number()
        .typeError('此為必填項目')
        .moreThan(0, '總分不得為負'),
    };
  },
  methods: {
    async addSurvey(values, { resetForm }) {
      const now = Date.now();
      // 準備上傳的量表物件
      const surveyData = _.cloneDeep(this.survey);
      surveyData.createdAt = now;
      surveyData.updatedAt = now;
      const loader = this.$loading.show();
      try {
        await addDoc(collection(db, 'mmse'), surveyData);
        loader.hide();
        // 新增成功，重新讀取計畫列表並清空表單
        resetForm();
        this.customReset();
        this.survey.note = '';
        this.$swal({
          text: '填寫成功',
          icon: 'success',
        });
      } catch (e) {
        loader.hide();
        resetForm();
        this.customReset();
        this.survey.note = '';
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    customReset() {
      // 清空量表物件
      this.cutoff = '';
      this.isOverflow = false;
    },
    calCutoff() {
      switch (this.survey.education) {
        case 1:
          this.isOverflow = false;
          this.cutoff = '低於16分表示認知功能異常';
          break;
        case 2:
          this.isOverflow = false;
          this.cutoff = '低於21分表示認知功能異常';
          break;
        case 3:
          this.isOverflow = true;
          this.cutoff = '低於24分為輕度認知功能缺損，低於16分為重度認知功能缺損';
          break;
        default:
          this.isOverflow = false;
          this.cutoff = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  height: 38px;
  align-items: center;
}
.display-auto-cal {
  height: 38px;
  line-height: 38px;
}
.survey-wrapper {
  background-color: $bright;
  border-bottom: 1px solid $primary;
}
.quest-wrapper {
  .row {
    border-bottom: 1px solid $primary;
  }
}
.q8 {
  font-size: 160px;
  color: black;
}
</style>
