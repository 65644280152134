<template>
  <VeeForm class="container" @submit="addSurvey">
    <VueLoading v-model:active="isLoading" color="#196bb0"></VueLoading>
    <div class="row">
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
        <h5>肌力 (Strength)</h5>
        <label class="form-label fs-5 mb-3">{{ qList[0] }}</label>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q1"
            id="q1_0"
            value="0"
            v-model.number="survey.q1"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q1_0">0分：沒有困難</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q1"
            id="q1_1"
            value="1"
            v-model.number="survey.q1"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q1_1">1分：有點困難</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q1"
            id="q1_2"
            value="2"
            v-model.number="survey.q1"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q1_2">2分：很困難/無法完成</label>
        </div>
        <ErrorMessage name="q1" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="total-score col-md-4 fs-5">
        總分：{{ survey.total }}
      </div>
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
        <h5>輔助行走 (Assistance in wailing)</h5>
        <label class="form-label fs-5 mb-3">{{ qList[1] }}</label>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q2"
            id="q2_0"
            value="0"
            v-model.number="survey.q2"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q2_0">0分：沒有困難</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q2"
            id="q2_1"
            value="1"
            v-model.number="survey.q2"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q2_1">1分：有點困難</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q2"
            id="q2_2"
            value="2"
            v-model.number="survey.q2"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q2_2">2分：很困難/需要幫忙/無法完成</label>
        </div>
        <ErrorMessage name="q2" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
        <h5>從椅子上站起來 (Rise from a chair)</h5>
        <label class="form-label fs-5 mb-3">{{ qList[2] }}</label>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q3"
            id="q3_0"
            value="0"
            v-model.number="survey.q3"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q3_0">0分：沒有困難</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q3"
            id="q3_1"
            value="1"
            v-model.number="survey.q3"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q3_1">1分：有點困難</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q3"
            id="q3_2"
            value="2"
            v-model.number="survey.q3"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q3_2">2分：很困難/需要幫忙/無法完成</label>
        </div>
        <ErrorMessage name="q3" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
        <h5>爬樓梯 (Climb stairs)</h5>
        <label class="form-label fs-5 mb-3">{{ qList[3] }}</label>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q4"
            id="q4_0"
            value="0"
            v-model.number="survey.q4"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q4_0">0分：沒有困難</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q4"
            id="q4_1"
            value="1"
            v-model.number="survey.q4"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q4_1">1分：有點困難</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q4"
            id="q4_2"
            value="2"
            v-model.number="survey.q4"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q4_2">2分：很困難/無法完成</label>
        </div>
        <ErrorMessage name="q4" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
      <div class="pb-3 mb-3 col-md-4 offset-md-4 border-bottom">
        <h5>跌倒 (Falls)</h5>
        <label class="form-label fs-5 mb-3">{{ qList[4] }}</label>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q5"
            id="q5_0"
            value="0"
            v-model.number="survey.q5"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q5_0">0分：沒有</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q5"
            id="q5_1"
            value="1"
            v-model.number="survey.q5"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q5_1">1分：1~3 次</label>
        </div>
        <div class="form-check">
          <Field
            class="form-check-input"
            type="radio"
            name="q5"
            id="q5_2"
            value="2"
            v-model.number="survey.q5"
            :rules="isRequired"
          />
          <label class="form-check-label" for="q5_2">2分：&ge; 4 次</label>
        </div>
        <ErrorMessage name="q5" v-slot="{ message }">
          <div class="d-flex align-items-center mt-1">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
            <p class="m-0">{{ message }}</p>
          </div>
        </ErrorMessage>
      </div>
    </div>
    <div class="mt-3 text-center col-md-4 offset-md-4">
      <button type="submit" class="btn btn-primary me-3">送出</button>
      <button type="reset" @click="customReset" class="btn btn-secondary">重置</button>
    </div>
  </VeeForm>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
// 載入 loading overlay
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// 載入 firestore
import { collection, addDoc } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 vee-validate
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { db } from '@/assets/firebase';
// 載入 sweetalert2
import useVueSweetAlert2 from '@/composables/useVueSweetAlert2';

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
    VueLoading,
  },
  props: {
    createrId: String,
    projectId: String,
    caseId: String,
  },
  setup(props) {
    const $swal = useVueSweetAlert2();
    // ================================
    const { createrId, projectId, caseId } = toRefs(props);
    const survey = ref({
      createrId,
      projectId,
      caseId,
      q1: null,
      q2: null,
      q3: null,
      q4: null,
      q5: null,
      total: 0, // 總分
    });
    const qList = ref([
      '提起並拿著 10 磅的重物，會感到困難嗎？',
      '在房間走動時，會感到困難嗎？',
      '從椅子/床鋪站起來時，會感到困難嗎？',
      '爬 10 階樓梯，會感到困難嗎？',
      '過去一年，跌倒幾次',
    ]);
    const isRequired = ref(
      yup
        .string()
        .typeError('此為必填項目')
        .required('此為必填項目'),
    );
    const isLoading = ref(false);

    function customReset() {
      // 清空量表物件
      survey.value = {
        createrId,
        projectId,
        caseId,
        q1: null,
        q2: null,
        q3: null,
        q4: null,
        q5: null,
        total: 0, // 總分
      };
    }
    async function addSurvey(values, { resetForm }) {
      const now = Date.now();
      // 準備上傳的量表物件
      const surveyData = _.cloneDeep(survey.value);
      surveyData.createdAt = now;
      surveyData.updatedAt = now;
      // 開始上傳
      isLoading.value = true;
      try {
        await addDoc(collection(db, 'sarcf'), surveyData);
        isLoading.value = false;
        customReset();
        resetForm();
        $swal.fire({
          text: '填寫成功',
          icon: 'success',
        });
      } catch (e) {
        isLoading.value = false;
        customReset();
        resetForm();
        $swal.fire({
          text: String(e),
          icon: 'error',
        });
      }
    }
    function calFinalScore() {
      survey.value.total = survey.value.q1
        + survey.value.q2
        + survey.value.q3
        + survey.value.q4
        + survey.value.q5;
    }
    // 監聽 survey 內各項參數變動，並進行即時計算
    watch(
      survey,
      () => {
        // 計算最新總分
        calFinalScore();
      },
      { deep: true },
    );

    return {
      survey,
      qList,
      isRequired,
      isLoading,
      addSurvey,
      customReset,
    };
  },
};
</script>

<style lang="scss" scoped>
div {
  border-color: $primary !important;
}
.total-score {
  position: sticky;
  top: 1rem;
}
</style>
