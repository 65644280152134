<template>
  <VeeForm class="container" @submit="addSurvey">
    <div class="row d-flex justify-content-center">
      <div class="col-2 fs-5 p-0 border d-flex justify-content-center align-items-center">
        依賴管灌進食
      </div>
      <div class="col-6 p-0 border-top border-bottom border-end">
        <div class="d-flex justify-content-between">
          <div class="ps-3 py-1">
            <label class="form-check-label" for="level-1">
              {{ qList[0] }}
            </label>
          </div>
          <div class="border-start px-3 py-1">
            <Field
              class="form-check-input"
              type="radio"
              name="level"
              id="level-1"
              value="1"
              v-model="survey.level"
              :rules="isRequired"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between border-top border-bottom">
          <div class="ps-3 py-1">
            <label class="form-check-label" for="level-2">
              {{ qList[1] }}
            </label>
          </div>
          <div class="border-start px-3 py-1">
            <Field
              class="form-check-input"
              type="radio"
              name="level"
              id="level-2"
              value="2"
              v-model="survey.level"
              :rules="isRequired"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="ps-3 py-1">
            <label class="form-check-label" for="level-3">
              {{ qList[2] }}
            </label>
          </div>
          <div class="border-start px-3 py-1">
            <Field
              class="form-check-input"
              type="radio"
              name="level"
              id="level-3"
              value="3"
              v-model="survey.level"
              :rules="isRequired"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div
        class="col-2 fs-5 p-0 border-start border-bottom
        border-end d-flex justify-content-center align-items-center"
      >
        完全口中進食
      </div>
      <div class="col-6 p-0 border-bottom border-end">
        <div class="d-flex justify-content-between">
          <div class="ps-3 py-1">
            <label class="form-check-label" for="level-4">
              {{ qList[3] }}
            </label>
          </div>
          <div class="border-start px-3 py-1">
            <Field
              class="form-check-input"
              type="radio"
              name="level"
              id="level-4"
              value="4"
              v-model="survey.level"
              :rules="isRequired"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between border-top border-bottom">
          <div class="ps-3 py-1">
            <label class="form-check-label" for="level-5">
              {{ qList[4] }}
            </label>
          </div>
          <div class="border-start px-3 py-1">
            <Field
              class="form-check-input"
              type="radio"
              name="level"
              id="level-5"
              value="5"
              v-model="survey.level"
              :rules="isRequired"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between border-bottom">
          <div class="ps-3 py-1">
            <label class="form-check-label" for="level-6">
              {{ qList[5] }}
            </label>
          </div>
          <div class="border-start px-3 py-1">
            <Field
              class="form-check-input"
              type="radio"
              name="level"
              id="level-6"
              value="6"
              v-model="survey.level"
              :rules="isRequired"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="ps-3 py-1">
            <label class="form-check-label" for="level-7">
              {{ qList[6] }}
            </label>
          </div>
          <div class="border-start px-3 py-1">
            <Field
              class="form-check-input"
              type="radio"
              name="level"
              id="level-7"
              value="7"
              v-model="survey.level"
              :rules="isRequired"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 text-center col-md-4 offset-md-4">
      <button type="submit" class="btn btn-primary me-3">送出</button>
      <button type="reset" class="btn btn-secondary">重置</button>
    </div>
  </VeeForm>
</template>

<script>
// 載入 firestore
import { collection, addDoc } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 vee-validate
import { Form as VeeForm, Field } from 'vee-validate';
import * as yup from 'yup';
import { db } from '@/assets/firebase';

export default {
  components: {
    VeeForm,
    Field,
  },
  props: {
    createrId: String,
    projectId: String,
    caseId: String,
  },
  data() {
    return {
      survey: {
        createrId: this.createrId,
        projectId: this.projectId,
        caseId: this.caseId,
        level: '',
      },
      qList: [
        '等級一：無任何口中進食',
        '等級二：依靠胃管，但有少量或不一致的口中進食',
        '等級三：一致的口中進食及胃管，以提供足夠營養',
        '等級四：完全口中進食，只靠單一質地的食物',
        '等級五：完全口中進食，可擁有不同質地的食物，但需要特殊處理',
        '等級六：完全口中進食，不需特殊處理，但需避免特定食物及液體',
        '等級七：完全口中進食，無任何限制',
      ],
      isRequired: yup.string().required('此為必填項目'),
    };
  },
  methods: {
    async addSurvey(values, { resetForm }) {
      const now = Date.now();
      // 準備上傳的量表物件
      const surveyData = _.cloneDeep(this.survey);
      surveyData.createdAt = now;
      surveyData.updatedAt = now;
      const loader = this.$loading.show();
      try {
        await addDoc(collection(db, 'fois'), surveyData);
        loader.hide();
        // 新增成功，重新讀取計畫列表並清空表單
        resetForm();
        this.$swal({
          text: '填寫成功',
          icon: 'success',
        });
      } catch (e) {
        loader.hide();
        resetForm();
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  border-color: $primary !important;
}
.form-check-label {
  cursor: pointer;
}
</style>
