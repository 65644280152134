<template>
  <VeeForm class="container" @submit="addSurvey">
    <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
      <label class="form-label fs-5 mb-3">{{ qList[0] }}</label>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="memory"
          id="memory_0"
          value="0"
          v-model.number="survey.memory"
          :rules="isRequired"
        />
        <label class="form-check-label" for="memory_0"
          >0分：沒有記憶力減退或稍微減退。沒有經常性健忘。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="memory"
          id="memory_0.5"
          value="0.5"
          v-model.number="survey.memory"
          :rules="isRequired"
        />
        <label class="form-check-label" for="memory_0.5"
          >0.5分：經常性的輕度遺忘，事情只能部分想起；「良性」健忘症。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="memory"
          id="memory_1"
          value="1"
          v-model.number="survey.memory"
          :rules="isRequired"
        />
        <label class="form-check-label" for="memory_1"
          >1分：中度記憶減退；對於最近的事尤其不容易記得；會影響日常生活。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="memory"
          id="memory_2"
          value="2"
          v-model.number="survey.memory"
          :rules="isRequired"
        />
        <label class="form-check-label" for="memory_2"
          >2分：嚴重記憶力減退只有高度重複學過的事務才會記得；新學的東西都很快會忘記。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="memory"
          id="memory_3"
          value="3"
          v-model.number="survey.memory"
          :rules="isRequired"
        />
        <label class="form-check-label" for="memory_3">3分：記憶力嚴重減退只能記得片段</label>
      </div>
      <ErrorMessage name="memory" v-slot="{ message }">
        <div class="d-flex align-items-center mt-1">
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
          <p class="m-0">{{ message }}</p>
        </div>
      </ErrorMessage>
    </div>
    <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
      <label class="form-label fs-5 mb-3">{{ qList[1] }}</label>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="orientation"
          id="orientation_0"
          value="0"
          v-model.number="survey.orientation"
          :rules="isRequired"
        />
        <label class="form-check-label" for="orientation_0">0分：完全能定向。</label>
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="orientation"
          id="orientation_0.5"
          value="0.5"
          v-model.number="survey.orientation"
          :rules="isRequired"
        />
        <label class="form-check-label" for="orientation_0.5"
          >0.5分：完全能定向，但涉及時間關聯性時，稍有困難。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="orientation"
          id="orientation_1"
          value="1"
          v-model.number="survey.orientation"
          :rules="isRequired"
        />
        <label class="form-check-label" for="orientation_1"
          >1分：涉及時間關聯性時，有中度困難。檢查時，對地點仍有定向力；但在某些場合可能仍有地理定向力的障礙。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="orientation"
          id="orientation_2"
          value="2"
          v-model.number="survey.orientation"
          :rules="isRequired"
        />
        <label class="form-check-label" for="orientation_2"
          >2分：涉及時間關聯性時，有嚴重困難；時間及地點都會有定向力的障礙。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="orientation"
          id="orientation_3"
          value="3"
          v-model.number="survey.orientation"
          :rules="isRequired"
        />
        <label class="form-check-label" for="orientation_3">3分：只維持對人的定向力。</label>
      </div>
      <ErrorMessage name="orientation" v-slot="{ message }">
        <div class="d-flex align-items-center mt-1">
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
          <p class="m-0">{{ message }}</p>
        </div>
      </ErrorMessage>
    </div>
    <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
      <label class="form-label fs-5 mb-3">{{ qList[2] }}</label>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="solve"
          id="solve_0"
          value="0"
          v-model.number="survey.solve"
          :rules="isRequired"
        />
        <label class="form-check-label" for="solve_0"
          >0分：日常問題(包括財務及商業性的事物)都能處理的很好；和以前的表現比較，判斷力良好。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="solve"
          id="solve_0.5"
          value="0.5"
          v-model.number="survey.solve"
          :rules="isRequired"
        />
        <label class="form-check-label" for="solve_0.5"
          >0.5分：處理問題時，在分析類似性和差異性時，稍有困難。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="solve"
          id="solve_1"
          value="1"
          v-model.number="survey.solve"
          :rules="isRequired"
        />
        <label class="form-check-label" for="solve_1"
          >1分：處理問題時，分析類似性和差異性時，有中度困難；社會價值之判斷力通常還能維持。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="solve"
          id="solve_2"
          value="2"
          v-model.number="survey.solve"
          :rules="isRequired"
        />
        <label class="form-check-label" for="solve_2"
          >2分：處理問題時，分析類似性和差異時有嚴重障礙；社會價值之判斷力通常已受影響。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="solve"
          id="solve_3"
          value="3"
          v-model.number="survey.solve"
          :rules="isRequired"
        />
        <label class="form-check-label" for="solve_3">3分：不能做判斷或解決問題。</label>
      </div>
      <ErrorMessage name="solve" v-slot="{ message }">
        <div class="d-flex align-items-center mt-1">
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
          <p class="m-0">{{ message }}</p>
        </div>
      </ErrorMessage>
    </div>
    <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
      <label class="form-label fs-5 mb-3">{{ qList[3] }}</label>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="activity"
          id="activity_0"
          value="0"
          v-model.number="survey.activity"
          :rules="isRequired"
        />
        <label class="form-check-label" for="activity_0"
          >0分：和平常一樣能獨立處理有關、工作、購物、業務、財務、參加義工及社團的事務。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="activity"
          id="activity_0.5"
          value="0.5"
          v-model.number="survey.activity"
          :rules="isRequired"
        />
        <label class="form-check-label" for="activity_0.5">0.5分：這些活動稍有障礙。</label>
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="activity"
          id="activity_1"
          value="1"
          v-model.number="survey.activity"
          :rules="isRequired"
        />
        <label class="form-check-label" for="activity_1"
          >1分：雖然還能從事有些活動。但無法單獨參與。對一般偶而的檢查 ，外觀上還似正常。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="activity"
          id="activity_2"
          value="2"
          v-model.number="survey.activity"
          :rules="isRequired"
        />
        <label class="form-check-label" for="activity_2"
          >2分：不會掩飾自己無力獨自處理工作、購物等活動的窘境。被帶出來外面活動時，外觀還似正常。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="activity"
          id="activity_3"
          value="3"
          v-model.number="survey.activity"
          :rules="isRequired"
        />
        <label class="form-check-label" for="activity_3"
          >3分：不會掩飾自己無力獨自處理工作、購物等活動的窘境。外觀上明顯可知病情嚴重，無法在外活動。</label
        >
      </div>
      <ErrorMessage name="activity" v-slot="{ message }">
        <div class="d-flex align-items-center mt-1">
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
          <p class="m-0">{{ message }}</p>
        </div>
      </ErrorMessage>
    </div>
    <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
      <label class="form-label fs-5 mb-3">{{ qList[4] }}</label>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="hobby"
          id="hobby_0"
          value="0"
          v-model.number="survey.hobby"
          :rules="isRequired"
        />
        <label class="form-check-label" for="hobby_0"
          >0分：家庭生活，嗜好，知性興趣都維持良好。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="hobby"
          id="hobby_0.5"
          value="0.5"
          v-model.number="survey.hobby"
          :rules="isRequired"
        />
        <label class="form-check-label" for="hobby_0.5"
          >0.5分：家庭生活，嗜好，知性興趣，稍有障礙。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="hobby"
          id="hobby_1"
          value="1"
          v-model.number="survey.hobby"
          :rules="isRequired"
        />
        <label class="form-check-label" for="hobby_1"
          >1分：居家生活確已出現輕度之障礙，較困難之家事已經不做；比較複雜之嗜好及興趣都已放棄。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="hobby"
          id="hobby_2"
          value="2"
          v-model.number="survey.hobby"
          :rules="isRequired"
        />
        <label class="form-check-label" for="hobby_2"
          >2分：只有簡單家事還能做興趣很少，也很難維持。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="hobby"
          id="hobby_3"
          value="3"
          v-model.number="survey.hobby"
          :rules="isRequired"
        />
        <label class="form-check-label" for="hobby_3">3分：無法做家事。</label>
      </div>
      <ErrorMessage name="hobby" v-slot="{ message }">
        <div class="d-flex align-items-center mt-1">
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
          <p class="m-0">{{ message }}</p>
        </div>
      </ErrorMessage>
    </div>
    <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
      <label class="form-label fs-5 mb-3">{{ qList[5] }}</label>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="care"
          id="care_0"
          value="0"
          v-model.number="survey.care"
          :rules="isRequired"
        />
        <label class="form-check-label" for="care_0">0分：能完全自我照料。</label>
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="care"
          id="care_0.5"
          value="0.5"
          v-model.number="survey.care"
          :rules="isRequired"
        />
        <label class="form-check-label" for="care_0.5">0.5分：能完全自我照料。</label>
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="care"
          id="care_1"
          value="1"
          v-model.number="survey.care"
          :rules="isRequired"
        />
        <label class="form-check-label" for="care_1">1分：需旁人督促或提醒。</label>
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="care"
          id="care_2"
          value="2"
          v-model.number="survey.care"
          :rules="isRequired"
        />
        <label class="form-check-label" for="care_2"
          >2分：穿衣、個人衛生、及個人事物之料理，都需要幫忙。</label
        >
      </div>
      <div class="form-check">
        <Field
          class="form-check-input"
          type="radio"
          name="care"
          id="care_3"
          value="3"
          v-model.number="survey.care"
          :rules="isRequired"
        />
        <label class="form-check-label" for="care_3"
          >3分：個人照料需仰賴別人給予很大的幫忙。經常大小便失禁。</label
        >
      </div>
      <ErrorMessage name="care" v-slot="{ message }">
        <div class="d-flex align-items-center mt-1">
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
          <p class="m-0">{{ message }}</p>
        </div>
      </ErrorMessage>
    </div>
    <div class="pb-3 mb-3 col-md-6 offset-md-3 border-bottom">
      <h5>【需由專業人士判斷】</h5>
      <p>
        4分：說話通常令人費解或毫無關聯，不能遵照簡單指示或不了解指令；偶而只能認出其配偶或照顧他的人。吃飯只會用手指頭不太會
        用餐具，也需要旁人協助。即使有人協助或加以訓練，還是經常大小便失禁。有人協助下雖然勉強能走幾步，通常都必須需要坐輪椅；極少到戶外去，且經常會有無目的的動作。
      </p>
      <p>
        5分：沒有反應或毫無理解能力。認不出人。需旁人餵食，可能需用鼻胃管。吞食困難。大小便完全失禁。長期躺在病床上，不能坐也不能站，全身關節攣縮。
      </p>
    </div>
    <div class="mt-3 text-center col-md-4 offset-md-4">
      <button type="submit" class="btn btn-primary me-3">送出</button>
      <button type="reset" class="btn btn-secondary">重置</button>
    </div>
  </VeeForm>
</template>

<script>
// 載入 firestore
import { collection, addDoc } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 vee-validate
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { db } from '@/assets/firebase';

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    createrId: String,
    projectId: String,
    caseId: String,
  },
  data() {
    return {
      survey: {
        createrId: this.createrId,
        projectId: this.projectId,
        caseId: this.caseId,
        memory: '', // 分數 0 ~ 3
        orientation: '',
        solve: '',
        activity: '',
        hobby: '',
        care: '',
      },
      qList: [
        '一、記憶力',
        '二、定向感',
        '三、解決問題能力',
        '四、社區活動能力',
        '五、家居嗜好',
        '六、自我照料',
      ],
      isRequired: yup.string().required('此為必填項目'),
    };
  },
  methods: {
    async addSurvey(values, { resetForm }) {
      const now = Date.now();
      // 準備上傳的量表物件
      const surveyData = _.cloneDeep(this.survey);
      surveyData.createdAt = now;
      surveyData.updatedAt = now;
      const loader = this.$loading.show();
      try {
        await addDoc(collection(db, 'cdr'), surveyData);
        loader.hide();
        // 新增成功，重新讀取計畫列表並清空表單
        resetForm();
        this.$swal({
          text: '填寫成功',
          icon: 'success',
        });
      } catch (e) {
        loader.hide();
        resetForm();
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
