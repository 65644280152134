<template>
  <div class="container">
    <div class="select-row row my-3 pb-3">
      <div class="col-md-4">
        <select class="form-select" v-model="selectedProjectId" @change="getCases">
          <option selected disabled hidden value="">--請選擇計畫--</option>
          <option
            v-for="(project, index) in projects"
            :value="project.id"
            :key="'project_' + index"
          >
            {{ project.name }}
          </option>
        </select>
      </div>
      <div class="col-md-4" v-show="selectedProjectId">
        <select class="form-select" v-model="selectedCaseId" @change="reset">
          <option selected disabled hidden value="">--請選擇個案--</option>
          <option v-for="(caseData, index) in cases" :value="caseData.id" :key="'case_' + index">
            {{ caseData.name }}
          </option>
        </select>
      </div>
      <div class="col-md-4" v-show="selectedCaseId">
        <select class="form-select" v-model="selectedSurvey">
          <option selected disabled hidden value="">--請選擇量表--</option>
          <option value="basicInfo">個案基本資料</option>
          <option value="eat10">吞嚥能力評估工具</option>
          <option value="fois">功能性由口進食量表</option>
          <option value="mnasf">迷你營養評估量表</option>
          <option value="sf36">身心健康狀況量表</option>
          <option value="sarcf">SARC-F 量表</option>
          <option value="ad8">極早期失智症篩檢量表</option>
          <option value="cdr">臨床失智評估量表</option>
          <option value="mmse">簡易心智量表</option>
        </select>
      </div>
    </div>
    <div class="row my-3">
      <BasicInfo
        v-if="selectedSurvey === 'basicInfo'"
        :projectId="selectedProjectId"
        :caseId="selectedCaseId"
        :createrId="userUid"
        :key="surveyKey.basicInfo"
      ></BasicInfo>
      <Eat10
        v-if="selectedSurvey === 'eat10'"
        :projectId="selectedProjectId"
        :caseId="selectedCaseId"
        :createrId="userUid"
        :key="surveyKey.eat10"
      ></Eat10>
      <Fois
        v-if="selectedSurvey === 'fois'"
        :projectId="selectedProjectId"
        :caseId="selectedCaseId"
        :createrId="userUid"
        :key="surveyKey.fois"
      ></Fois>
      <Mnasf
        v-if="selectedSurvey === 'mnasf'"
        :projectId="selectedProjectId"
        :caseId="selectedCaseId"
        :createrId="userUid"
        :key="surveyKey.mnasf"
      ></Mnasf>
      <SF36
        v-if="selectedSurvey === 'sf36'"
        :projectId="selectedProjectId"
        :caseId="selectedCaseId"
        :createrId="userUid"
        :key="surveyKey.sf36"
      ></SF36>
      <Sarcf
        v-if="selectedSurvey === 'sarcf'"
        :projectId="selectedProjectId"
        :caseId="selectedCaseId"
        :createrId="userUid"
        :key="surveyKey.sarcf"
      ></Sarcf>
      <Ad8
        v-if="selectedSurvey === 'ad8'"
        :projectId="selectedProjectId"
        :caseId="selectedCaseId"
        :createrId="userUid"
        :key="surveyKey.ad8"
      ></Ad8>
      <Cdr
        v-if="selectedSurvey === 'cdr'"
        :projectId="selectedProjectId"
        :caseId="selectedCaseId"
        :createrId="userUid"
        :key="surveyKey.cdr"
      ></Cdr>
      <Mmse
        v-if="selectedSurvey === 'mmse'"
        :projectId="selectedProjectId"
        :caseId="selectedCaseId"
        :createrId="userUid"
        :key="surveyKey.mmse"
      ></Mmse>
    </div>
  </div>
</template>

<script>
// 載入 firestore
// eslint-disable-next-line object-curly-newline
import { query, collection, where, getDocs } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
import { db } from '@/assets/firebase';
import BasicInfo from '@/components/survey/BasicInfo.vue';
import Eat10 from '@/components/survey/Eat10.vue';
import Fois from '@/components/survey/Fois.vue';
import Mnasf from '@/components/survey/Mnasf.vue';
import SF36 from '@/components/survey/SF36.vue';
import Sarcf from '@/components/survey/Sarcf.vue';
import Ad8 from '@/components/survey/Ad8.vue';
import Cdr from '@/components/survey/Cdr.vue';
import Mmse from '@/components/survey/Mmse.vue';

export default {
  components: {
    BasicInfo,
    Eat10,
    Fois,
    Mnasf,
    SF36,
    Sarcf,
    Ad8,
    Cdr,
    Mmse,
  },
  props: {
    userUid: String,
    userEmail: String,
  },
  data() {
    return {
      projects: [],
      cases: [],
      selectedProjectId: '',
      selectedCaseId: '',
      selectedSurvey: '',
      surveyKey: {
        basicInfo: 'basicInfo',
        eat10: 'eat10',
        fois: 'fois',
        mnasf: 'mnasf',
        sf36: 'sf36',
        sarcf: 'sarcf',
        ad8: 'ad8',
        cdr: 'cdr',
        mmse: 'mmse',
      },
    };
  },
  methods: {
    async getProjects() {
      // 清空 projects
      this.projects = [];
      const loader = this.$loading.show();
      try {
        // 搜尋為「擁有者」的計畫
        const q1 = query(collection(db, 'projects'), where('admin', '==', this.userUid));
        let querySnapshot = await getDocs(q1);
        querySnapshot.forEach((project) => {
          const projectData = _.cloneDeep(project.data());
          projectData.id = project.id;
          this.projects.push(projectData);
        });
        // 搜尋為「管理者」的計畫
        const q2 = query(
          collection(db, 'projects'),
          where('coAdmin', 'array-contains', this.userUid),
        );
        querySnapshot = await getDocs(q2);
        querySnapshot.forEach((project) => {
          const projectData = _.cloneDeep(project.data());
          projectData.id = project.id;
          this.projects.push(projectData);
        });
        // 搜尋為「訪視者」的計畫
        const q3 = query(collection(db, 'projects'), where('coop', 'array-contains', this.userUid));
        querySnapshot = await getDocs(q3);
        querySnapshot.forEach((project) => {
          const projectData = _.cloneDeep(project.data());
          projectData.id = project.id;
          this.projects.push(projectData);
        });
        loader.hide();
      } catch (e) {
        loader.hide();
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async getCases() {
      // reset
      this.cases = [];
      this.selectedCaseId = '';
      this.selectedSurvey = '';
      const loader = this.$loading.show();
      try {
        // 取得所有該計畫的個案
        const querySnapshot = await getDocs(
          collection(db, 'projects', this.selectedProjectId, 'cases'),
        );
        querySnapshot.forEach((caseData) => {
          const casesData = _.cloneDeep(caseData.data());
          casesData.id = caseData.id;
          this.cases.push(casesData);
        });
        loader.hide();
      } catch (e) {
        loader.hide();
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    reset() {
      if (this.selectedSurvey) {
        this.surveyKey[this.selectedSurvey] = `${this.selectedSurvey}_${Date.now()}`;
      }
    },
  },
  created() {
    this.getProjects();
  },
};
</script>

<style lang="scss" scoped>
.container {
  color: $primary;
}
select {
  color: $primary;
  option {
    color: $primary;
  }
}
.select-row {
  border-bottom: 2px solid $primary;
}
</style>
